<template>
  <div>
    <section class="home-restaurants">
      <div class="row image-copy-split">
        <div class="columns large-6 col-copy">
          <h2>
            Collaborations<br />
            with Award-Winning<br />
            Restaurants
          </h2>
        </div>
        <div
          class="columns large-6 image-col"
          :style="{
            'background-image': `url('${restaurantImage}')`,
            'background-size': 'cover',
          }"
        />
      </div>
    </section>
    <section class="home-standard">
      <div class="row">
        <div class="columns text-center">
          <div class="small-caps-text">Shop by Restaurant</div>
          <h2>Exclusive Collections</h2>
          <div class="carousel-container site-width">
            <div id="restaurant-tabs" class="tabs">
              <div
                v-for="({ name, slug, extended_data },
                index) in restaurantOccasions"
                :id="slug"
                :key="index"
                v-scroll-to="{
                  el: `#${slug}`,
                  container: '#restaurant-tabs',
                  offset: -100,
                  x: true,
                  y: false,
                }"
                class="tab"
                :class="{ active: index === activeRestaurant }"
                :data-collection="slug"
                @click="activeRestaurant = index"
              >
                {{ extended_data.michelin_restaurant_name || name }}
              </div>
            </div>
            <div
              :key="`${carouselRestaurantPrefix}carousel-${activeRestaurant}${restaurantFetchCount}`"
            >
              <ProductGridCarousel
                :carousel-id="carouselRestaurantPrefix + activeRestaurant"
                :items="restaurantItems"
                :title="null"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <intersect @enter.once="qesDisplayed = true">
      <section class="email-signup">
        <QuickEmailSignupModal
          v-if="showQES"
          :open="qesDisplayed"
          @closed="qesDisplayed = false"
        />
      </section>
    </intersect>
  </div>
</template>

<script>
import Vue from 'vue'
import Intersect from 'vue-intersect'
import { mapGetters, mapActions } from 'vuex'
import { scrollToProductInCarousel } from '~/utils/product'
export default {
  name: 'HomeRestaurantsSection',
  components: {
    Intersect,
    ProductGridCarousel: () =>
      import('~/components/Product/ProductGridCarousel'),
    QuickEmailSignupModal: () =>
      import('~/components/Index/QuickEmailSignupModal.vue'),
  },
  data() {
    return {
      restaurantOccasions: [],
      activeRestaurant: 0,
      carouselRestaurantPrefix: 'restaurant-',
      qesDisplayed: false,
    }
  },
  computed: {
    // Module-level getters
    ...mapGetters('restaurant', ['restaurantFetchCount']),
    restaurantItems() {
      const res = this.restaurantOccasions[this.activeRestaurant]
      if (res) return res.products
      return []
    },
    restaurantImage() {
      let imageString = ''
      if (this.restaurantOccasions.length > 0) {
        imageString = this.restaurantOccasions[this.activeRestaurant]
          .extended_data.michelin_image
        if (imageString) {
          imageString = this.$config.cloudfrontBase + imageString
        }
      }
      return (
        imageString || require('~/assets/images/homepage-restaurant-image.png')
      )
    },
    showQES() {
      return (
        this.qesDisplayed &&
        !this.loggedIn &&
        !(this.qes || this.$cookies.get('new-quicksignup'))
      )
    },
  },
  watch: {
    activeRestaurant(newValue) {
      this.handleActiveRestaurantChange(newValue)
    },
    restaurantOccasions(newValue) {
      if (newValue.length > 0) {
        this.handleActiveRestaurantChange(this.activeRestaurant)
      }
    },
  },
  async mounted() {
    await this.fetchMichelinRestaurantOccasions()
  },
  methods: {
    ...mapActions('restaurant', ['incrementRestaurantFetchCount']),
    async fetchMichelinRestaurantOccasions() {
      try {
        this.restaurantOccasions = (
          await this.$axios.get('/api/homepage/michelin-restaurant-data')
        ).data.michelin_restaurant_occasions
      } catch (error) {
        console.error(
          'Unable to fetch michelin restaurant occasions data',
          error
        )
      }
    },
    async fetchRestaurantsProducts(index) {
      const occasion = this.restaurantOccasions[index]
      if (!occasion.products) {
        try {
          const response = await this.$axios.get(
            `/api/catalog/search/?occasions=${occasion.slug}&page_size=12`
          )
          // Use Vue.set to preserve reactivity when adding products dynamically
          Vue.set(
            this.restaurantOccasions[index],
            'products',
            response.data.results
          )
          await this.incrementRestaurantFetchCount()
        } catch (error) {
          console.error('Error fetching restaurant products:', error)
        }
      }
    },
    async handleActiveRestaurantChange(index) {
      await this.fetchRestaurantsProducts(index)
      scrollToProductInCarousel(this.$store, this.$nextTick)
    },
  },
}
</script>
