var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"home-restaurants"},[_c('div',{staticClass:"row image-copy-split"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"columns large-6 image-col",style:({
          'background-image': ("url('" + _vm.restaurantImage + "')"),
          'background-size': 'cover',
        })})])]),_vm._v(" "),_c('section',{staticClass:"home-standard"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"columns text-center"},[_c('div',{staticClass:"small-caps-text"},[_vm._v("Shop by Restaurant")]),_vm._v(" "),_c('h2',[_vm._v("Exclusive Collections")]),_vm._v(" "),_c('div',{staticClass:"carousel-container site-width"},[_c('div',{staticClass:"tabs",attrs:{"id":"restaurant-tabs"}},_vm._l((_vm.restaurantOccasions),function(ref,index){
        var name = ref.name;
        var slug = ref.slug;
        var extended_data = ref.extended_data;
return _c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: ("#" + slug),
                container: '#restaurant-tabs',
                offset: -100,
                x: true,
                y: false,
              }),expression:"{\n                el: `#${slug}`,\n                container: '#restaurant-tabs',\n                offset: -100,\n                x: true,\n                y: false,\n              }"}],key:index,staticClass:"tab",class:{ active: index === _vm.activeRestaurant },attrs:{"id":slug,"data-collection":slug},on:{"click":function($event){_vm.activeRestaurant = index}}},[_vm._v("\n              "+_vm._s(extended_data.michelin_restaurant_name || name)+"\n            ")])}),0),_vm._v(" "),_c('div',{key:(_vm.carouselRestaurantPrefix + "carousel-" + _vm.activeRestaurant + _vm.restaurantFetchCount)},[_c('ProductGridCarousel',{attrs:{"carousel-id":_vm.carouselRestaurantPrefix + _vm.activeRestaurant,"items":_vm.restaurantItems,"title":null}})],1)])])])]),_vm._v(" "),_c('intersect',{on:{"~enter":function($event){_vm.qesDisplayed = true}}},[_c('section',{staticClass:"email-signup"},[(_vm.showQES)?_c('QuickEmailSignupModal',{attrs:{"open":_vm.qesDisplayed},on:{"closed":function($event){_vm.qesDisplayed = false}}}):_vm._e()],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns large-6 col-copy"},[_c('h2',[_vm._v("\n          Collaborations"),_c('br'),_vm._v("\n          with Award-Winning"),_c('br'),_vm._v("\n          Restaurants\n        ")])])}]

export { render, staticRenderFns }